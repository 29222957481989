.select {
  position: static;
  &__menu-list {
    z-index: 100;
  }

  .select__indicator {
    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
}
