.packer-modal {
  * {
    color: #2b2b2b;
  }

  .modal {
    &__content {
      width: 100%;
      max-width: 49.5vw;
      border-radius: 0;
      height: 100vh;
      max-height: none !important;
      top: 0;
      right: 0;
      left: auto;
      transform: none;
      padding: 1.5vw 2.5vw;
      overflow: visible;
    }

    &__box {
      height: 100%;
      padding-left: 2px;
    }

    &__header {
      display: none;
    }
  }

  h2 {
    font-size: 1.9vw;
    line-height: 2.3vw;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.75vw;
  }

  li {
    display: flex;
    gap: 1.15vw;
    background: linear-gradient(0deg, rgba(41, 52, 98, 0.05), rgba(41, 52, 98, 0.05)), #ffffff;
    border-radius: 0.9vw;
    padding: 1.25vw 0.75vw;

    .message-text {
      font-weight: 600;
      font-size: 1.5vw;
      line-height: 1.8vw;
    }

    .message-date {
      display: flex;
      align-items: flex-end;
    }
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3vw;
    height: 3vw;
    position: absolute;
    top: 3.5vw;
    left: 0;
    padding: 0.5vw;
    transform: translateX(-100%);

    background: #ffffff;
    // box-shadow: inset -0.05vw 0vw 0.25vw rgba(0, 0, 0, 0.1);
    border-radius: 2.5vw 0vw 0vw 2.5vw;

    svg {
      display: block;
      width: 1.1vw;
    }

    path {
      fill: #ee1e50;
    }
  }

  .print {
    position: sticky;
    bottom: 0px;
    margin-top: auto;
    width: max-content;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  label {
    font-size: 22px !important;
  }


  .select__single-value,
  .select__option,
  .select__placeholder {
    font-size: 20px !important;
  }

  .select__input {
    font-size: 18px !important;
    min-width: 150px;
  }

  .select_control {
    height: 50px;
  }

  input {
    width: max-content;
  }

  .form-check-input {
    width: 18px;
    height: 18px;
  }

  .form-check {
    align-items: center;
  }
}