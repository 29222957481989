.packer__product-info {
  .modal {
    &__header {
      display: none;
    }

    &__content {
      min-width: 44.5vw;
      width: 44.5vw;
      overflow: visible;
      border-radius: 1vw 0vw 1vw 1vw;
    }
  }

  .close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 3vw;
    height: 3vw;
    right: 0;
    transform: translateX(100%);

    background: #ffffff;
    box-shadow: inset 0.05vw 0vw 0.25vw rgba(0, 0, 0, 0.1);
    border-radius: 0 2.5vw 2.5vw 0;

    svg {
      width: 1.25vw;
      height: 1.25vw;
    }

    path {
      fill: #ee1e50;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 1.9vw;
    line-height: 2.3vw;
    color: #2b2b2b;
  }

  .status {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background: #ee1e50;
    border-radius: 0.6vw;
    padding: 0.5vw 1vw;

    span {
      display: block;
      width: 0.8vw;
      height: 0.8vw;
      border-radius: 50%;

      background-color: #ffffff;
    }

    p {
      font-weight: 500;
      font-size: 1vw;
      line-height: 1.3vw;
      letter-spacing: -0.01em;
      color: #ffffff;
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: 0.75vw;
    margin-top: 1vw;

    font-weight: 500;
    font-size: 1.9vw;
    line-height: 2.3vw;

    .icon {
      width: max-content;
      height: max-content;
      padding: 1vw;
      border-radius: 0.6vw;
      background-color: #2b2b2b;

      svg {
        display: block;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    svg {
      display: block;
    }

    .icon {
      display: flex;
      padding: 0.75vw;
      border-radius: 50%;
      &__donate {
        background: #ffeff3;
      }

      &__coin {
        background-color: #d6fde6;
      }
    }
  }

  .txt {
    &-1 {
      font-weight: 500;
      font-size: 1.5vw;
      line-height: 1.8vw;
      text-align: right;
      letter-spacing: -0.01em;
      color: #6a6a6a;
    }

    &-2 {
      font-weight: 600;
      font-size: 1.5vw;
      line-height: 1.8vw;
      text-align: right;
      letter-spacing: -0.01em;
    }
  }

  .user {
    display: flex;
    align-items: center;
    gap: 0.5vw;

    img {
      width: 3vw;
      height: 3vw;
      border-radius: 50%;

      border: 0.15vw solid #ffffff;

      filter: drop-shadow(0.1vw 0.25vw 0.75vw rgba(100, 108, 143, 0.1));
    }

    h4 {
      font-weight: 600;
      font-size: 1.2vw;
      line-height: 1.5vw;
      letter-spacing: -0.01em;
      color: #293462;
    }

    h5 {
      font-weight: 500;
      font-size: 1.3vw;
      line-height: 1.5vw;
    }

    p {
      font-weight: 500;
      font-size: 1vw;
      line-height: 1.3vw;
      letter-spacing: -0.01em;
      color: #2f8af5;
    }

    svg {
      width: 1.5vw;
      height: 1.5vw;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3vw;
      height: 3vw;
      border-radius: 50%;
      background-color: #eeeeee;
      color: #2b2b2b;

      font-weight: 500;
      font-size: 1.2vw;
      line-height: 1.5vw;
    }
  }

  .group2 {
    margin-top: 1.25vw;
    background-color: #fafbfe;
    border-top: 0.05vw solid #dde1f1;
    border-radius: 1vw 0vw 1vw 1vw;

    .user {
      gap: 0.75vw;
    }
  }
}
