@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

.packer-layout {
  font-family: "Inter", sans-serif;
  font-weight: 500;

  * {
    color: #293462;
  }
}

.packer {
  min-height: 100vh;
  background-color: #2934620d;

  svg {
    width: 1.5vw !important;
    height: 1.5vw !important;
  }

  .packer-icon__checked,
  .packer-icon__unchecked {
    width: 1.5vw !important;
    height: 1.5vw !important;
  }

  &-nav {
    padding: 0.75vw 1vw;
    padding-right: 3vw;
    background-color: #ffffff;
    border-bottom: 0.1vw solid #dde1f1;

    .col {
      padding: 0;
      display: flex;
      align-items: center;
      gap: 2.5vw;

      &.center {
        display: grid;
      }
    }

    .total {
      display: flex;
      align-items: center;
      gap: 0.5vw;

      &__icon {
        height: max-content;
        background: linear-gradient(0deg, rgba(41, 52, 98, 0.05), rgba(41, 52, 98, 0.05)), #ffffff;
        border-radius: 50%;

        svg {
          width: 2.5vw !important;
          height: 2.5vw !important;
          display: block;
        }
      }

      p {
        font-weight: 500;
        font-size: 1.2vw;
        line-height: 1.5vw;
        color: #2b2b2b;
      }
    }

    .select-all {
      display: flex;
      align-items: center;
      gap: 0.5vw;
      background: #78b3f8;
      align-items: center;
      justify-content: center;
      height: max-content;
      height: 2.5vw;
      padding: 0.5vw 1vw 0.5vw 0.5vw;
      border-radius: 0.6vw;

      p {
        font-size: 1.2vw;
        line-height: 1.5vw;
        letter-spacing: -0.01em;
        color: #ffffff;
      }
    }

    .switch {
      display: flex;
      align-items: center;
      gap: 0.75vw;
      background: linear-gradient(0deg, rgba(41, 52, 98, 0.05), rgba(41, 52, 98, 0.05)), #ffffff;
      border-radius: 0.6vw;
      padding: 0.5vw 1vw;

      &.checked {
        .switch__control {
          background-color: #3fda7d;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        width: 4.3vw;
        height: 2vw;
        background: #d8d8d8;
        border-radius: 25vw;
        padding: 0.25vw;
      }

      &__control-icon {
        div {
          height: 1.5vw;
        }
      }

      &-text {
        font-weight: 500;
        font-size: 1.2vw;
        line-height: 1.5vw;
        letter-spacing: -0.01em;
        color: #6a6a6a;
      }
    }

    .history,
    .notification {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3vw;
      min-width: 3vw;
      height: 3vw;
      border-radius: 50%;
    }

    .restore {
      @extend .notification;
      cursor: pointer;
      background: linear-gradient(0deg, rgba(41, 52, 98, 0.05), rgba(41, 52, 98, 0.05)), #ffffff;
      background-color: transparent !important;
    }

    .history {
      background-color: #d3e7ff;
    }

    .notification {
      position: relative;
      background-color: #ffeff3;

      .count {
        position: absolute;
        top: 0;
        left: 2vw;
        color: #fff;
        padding: 0.1vw 0.35vw;
        border-radius: 2.5vw;
        font-size: 0.8vw;
        background-color: #ee1e50;
        border: 0.1vw solid #ffffff;
      }
    }

    .go-back {
      display: flex;
      align-items: center;
      gap: 0.5vw;
      padding: 0.85vw 1vw;

      background: linear-gradient(0deg, rgba(41, 52, 98, 0.05), rgba(41, 52, 98, 0.05)), #ffffff;
      border-radius: 0.5vw;

      svg {
        width: 1.2vw;
      }

      p {
        font-size: 1vw;
        line-height: 1.3vw;

        color: #293462;
      }
    }

    .employee {
      display: flex;
      align-items: center;
      gap: 0.75vw;

      h2 {
        font-size: 1.2vw;
        line-height: 1.5vw;
        letter-spacing: -0.01em;
        color: #6a6a6a;
      }

      &__numbers {
        display: flex;
      }

      &__number {
        width: 3vw;
        height: 3vw;
        border-radius: 50%;
        display: grid;
        place-items: center;
        background: #ffc004;
        border: 0.15vw solid #ffffff;
        box-shadow: 0.1vw 0.25vw 0.75vw rgba(100, 108, 143, 0.1);
        z-index: var(--i);
        font-size: 1.1vw;
        line-height: 1.5vw;

        &:not(:first-child) {
          margin-left: -5%;
        }

        &:hover {
          z-index: 100;
          box-shadow: rgba(50, 50, 93, 0.25) 0vw 0.65vw 1.35vw -0.25vw,
            rgba(0, 0, 0, 0.3) 0vw 0.4vw 0.8vw -0.4vw;
        }
      }
    }
  }

  &-container {
    max-width: 90vw;
    width: 100%;
    margin: 0 auto;
  }

  &-wrapper {
    // padding-top: 1vw;
    overflow: hidden;
    // padding-bottom: 1vw;
  }

  .page {
    padding: 0 1vw;

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
      gap: 2.5vw;
      row-gap: 1vw;
    }

    li {
      list-style: none;
    }
  }
}

.print {
  display: flex;
  align-items: center;
  gap: 0.5vw;
  position: fixed;
  bottom: 1vw;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffcc33;
  box-shadow: 0.1vw 0.5vw 0.5vw rgba(255, 204, 51, 0.25);
  border-radius: 1vw;
  padding: 1.65vw 6.25vw;
  z-index: 10;
  cursor: pointer;

  p {
    font-weight: 600;
    font-size: 1.3vw;
    line-height: 1.5vw;
    color: #293462;
  }

  span {
    display: block;
    background-color: #fff;
    border-radius: 2.5vw;
    padding: 0.2vw 0.5vw;
    font-size: 1.3vw;
    line-height: 1.5vw;
  }

  li {
    position: relative;
    z-index: 10;
  }
}

.modal-print {
  @extend .print;
  position: static;
  transform: none;
  margin: 0 auto;
  margin-top: 10px;

  &.print-approve {
    background-color: #3fda7d;

    p {
      color: #fff;
    }
  }
}

.packer__modal-print {
  .modal__content {
    right: 0;
    left: auto;
    top: 0;
    max-height: none;
    height: 100vh !important;
    transform: none;
    border-radius: 0;
    overflow: visible;
    max-width: 58.188vw;
    height: 100vh;
    max-height: none !important;
  }

  .modal__box {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modal__header {
    padding: 0;
  }

  .order-count {
    display: grid;
    place-items: center;
    width: 11.875vw;
    height: 8.125vw;

    background: #ee1e50;
    color: #fff;

    font-weight: 700;
    font-size: 4.25vw;
    line-height: 5.25vw;
  }

  .modal__title {
    font-weight: 700;
    font-size: 2.375vw;
    line-height: 2.875vw;
    height: max-content;
    margin: auto 0;
    padding-left: 1.875vw;
  }

  .close-icon {
    display: grid;
    place-items: center;
    position: absolute;
    top: 4.375vw;
    left: 0;
    width: 3.75vw;
    height: 3.75vw;
    transform: translate(-100%, 0);

    background: #ffffff;
    box-shadow: inset -0.063vw 0vw 0.313vw rgba(0, 0, 0, 0.1);
    border-radius: 3.125vw 0vw 0vw 3.125vw;

    svg {
      width: 1.875vw;
      height: 1.875vw;
    }

    path {
      fill: #ee1e50;
    }
  }

  table {
    width: 100%;
    background-color: #fff2ca;
    border-collapse: collapse;
    margin-bottom: 3.125vw;
  }

  tr:not(:last-child) {
    border-bottom: 0.125vw solid #000;
  }

  td:not(:last-child) {
    border-right: 0.125vw solid #000;
  }

  td {
    padding: 1.875vw;
  }

  td:not(:first-child) {
    p {
      text-align: center;
      margin: 0 auto;
    }
  }

  .order {
    &__product {
      font-weight: 600;
      font-size: 1.875vw;
      line-height: 2.25vw;
    }

    &__item {
      width: max-content;
      font-weight: 600;
      font-size: 1.375vw;
      line-height: 1.75vw;
      padding: 0.125vw 0.625vw;
      color: #fff;

      background: #ee1e50;
    }

    &__item-count {
      font-weight: 700;
      font-size: 2.375vw;
      line-height: 2.875vw;

      color: #ee1e50;
    }
  }
}
