* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  background-color: #fff;
  font-size: 14px;
  height: 100%;
  line-height: 1.42857143;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans",
    "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: var(--bs-body-color);
  background-color: #fafbfd;
}

img,
picture,
video {
  display: block;
  max-width: 100%;
}

input,
textarea,
button,
select {
  font: inherit;
  border: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

h3 {
  font-size: 18px;
  line-height: 30px;
  text-align: center;

  background-color: #72809d;
  color: #fff !important;
}

.app {
  min-width: calc(100vw - 15px);
  min-height: 100vh;
}

.form-group {
  margin-bottom: 0;
  padding-bottom: 20px;
}

#react-select-3-listbox {
  z-index: 100;
}

.form__btns {
  display: flex;
  gap: 20px;

  .btn:last-child {
    color: #72809d;
  }

  span {
    text-decoration: underline;

    &:hover {
      color: #3498db;
    }
  }
}

.boolen-field {
  display: flex;
  justify-content: center;

  path {
    fill: #fff;
  }

  &--true {
    path {
      fill: rgb(108, 171, 13);
    }
  }

  &--false {
    path {
      fill: #72809d;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    padding-right: 2px;
    padding-top: 1px;
  }
}

.form__error {
  // position: absolute;
  margin-bottom: -10px;
  color: rgb(239, 92, 92);
}

.btn-back {
  display: flex;
  align-items: center;
  gap: 0.521vw;
  width: max-content;
  padding: 0.885vw 1.042vw;
  background: linear-gradient(0deg, rgba(41, 52, 98, 0.05), rgba(41, 52, 98, 0.05)), #ffffff;
  border-radius: 10px;
  cursor: pointer;

  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #293462;
  }
}

.form {
  &-content {
    margin-top: 20px;
  }

  &-price {
    margin-top: 20px;

    &__info {
      p {
        margin-bottom: 20px;
      }
    }
  }

  &__user-group {
    display: grid;
    gap: 20px;
    margin: 20px 0;
  }

  &-income-outcome,
  &-comment {
    margin: 30px 0;
  }
}

.modal {
  .btn-group {
    display: flex;
    gap: 10px;
  }

  &__create-position {
    padding: 20px;
  }

  &__similar {
    .btn-group {
      padding: 0 20px 20px 20px;
    }
  }

  &__comment {
    padding: 20px;
  }

  &__user-group {
    input {
      width: 1.143em;
      height: 1.143em;
      appearance: none;
    }

    .indeterminate {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: #6a6cf7;
      border: none;

      &::before {
        display: block;
        content: "";
        width: 5px;
        height: 2px;
        position: absolute;
        background-color: #fff;
      }
    }
  }

  &__basket {
    .form-group {
      min-width: 200px;
    }
  }
}

.tippy-popper {
  margin-top: -50px;
  transition: none !important;
  max-width: 200px;
}

[disabled] {
  background-color: rgba(59, 59, 59, 0.1) !important;
}

.row {
  @media (max-width: 600px) {
    display: block;
  }
}

.close {
  cursor: pointer;
}

.btn-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.order-status {
  padding: 1px 10px;
  border-radius: 20px;
  font-weight: 500;
  color: #fff;
  text-align: center;

  &-spam {
    background-color: #72809d !important;
  }

  &-archived {
    background-color: #b9c0d5 !important;
  }

  &-pending {
    background-color: #ff9f7c !important;
  }

  &-cancelled {
    background-color: #ff0000 !important;
  }

  &-sent,
  &-delivered {
    background-color: #91c483 !important;
  }

  &-accepted {
    background-color: #3db2ff !important;
  }
}
