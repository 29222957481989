.packer__modal-print.info {

    table {
        margin-bottom: 0;
    }

    ul {
        background-color: #F7F7F7;
        padding: 2.083vw 1.563vw;
    }

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 1.042vw;
        }
    }

    .key,
    .value {
        font-weight: 600;
        font-size: 1.563vw;
        line-height: 1.875vw;
    }

    .key {
        color: #505050;
    }

    .status {
        display: flex;
        align-items: center;
        gap: 0.26vw;
        border-radius: 0.625vw;
        background-color: #EE1E50;
        padding: 0.521vw;

        span {
            display: block;
            width: 0.833vw;
            height: 0.833vw;
            border-radius: 50%;
            background-color: #fff;
        }

        p {
            font-weight: 500;
            font-size: 1.042vw;
            line-height: 1.354vw;
            color: #fff;
        }
    }

    .id {
        font-weight: 500;
        font-size: 1.563vw;
        line-height: 1.875vw;
        color: #2F8AF5;
        text-align: end;
    }
}